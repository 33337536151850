
import { defineComponent } from "vue";
import Card from "primevue/card";
import NotificationCard from "@/components/Notification-Card.vue";
import { mapActions, mapGetters } from "vuex";
import SoQuoteDialog from "@/components/Sales/SoQuoteDialog.vue";
import SalesQuotesDataTableWrapper from "@/components/Sales/SalesQuotesDataWrapper.vue";
import SalesHeaderButtons from "@/components/Sales/SalesHeaderButtons.vue";
export default defineComponent({
  components: {
    SoQuoteDialog,
    NotificationCard,
    SalesQuotesDataTableWrapper,
    Card,
    SalesHeaderButtons,
  },
  computed: {
    ...mapGetters({
      getNewQuotesCount: "salesInquiry/getNewQuotesCount",
    }),
  },
  data() {
    return {
      selectedSoId: null,
      selectedCustomer: null,
      isLoadingSoQuotes: false,
      showSoQuoteDialog: false,
      isEditing: false,
      newQuote: false,
      readOnly: true,
      soQuotes: [],
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: "",
      singleOption: false,
      page: 1,
      order: false,
      orderRecordsBy: "",
      soQuote: null as unknown,
      isResultView: true as boolean,
      lastUpdated: Date.now(),
      isProduction: this.isProduction,
    };
  },
  inject: ["isProduction"],
  methods: {
    ...mapActions({
      addOpenedSalesOrder: "salesInquiry/addOpenedSalesInquiry",
    }),
    printExportData() {
      (this.$refs as any).salesQuoteTable.printTable();
    },
    clearFilters() {
      (this.$refs as any).salesQuoteTable.clearAllFiltersAndFetch();
    },
    openSoQuote(edit: boolean) {
      if (this.isProduction) {
        this.newQuote = !edit;
        this.showSoQuoteDialog = true;
        this.isEditing = edit;
        this.singleOption = true;
      } else {
        const count =
          this.getNewQuotesCount === undefined ? 1 : this.getNewQuotesCount + 1;
        const itemToEdit = {
          id: `New Quote-${count}`,
          saleType: "quotes",
          oldRecord: {},
        };

        this.addOpenedSalesOrder(itemToEdit);
        this.$router.push(`/sales/quotes/${itemToEdit.id}`);
      }
    },
    isResultViewChange(event: boolean) {
      this.isResultView = event;
    },
    isDataUpdated(event: number) {
      this.lastUpdated = event;
    },
  },
});
